import apiBusinessPartner from './apibusinesspartner';
import dashboard from './dashboard';
import reports from './reports';
import sampleResponse from './sampleRepose';
import testBanks from './testBanks';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, reports, apiBusinessPartner, sampleResponse , testBanks],
};

export default menuItems;
